import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/Container'
import { Heading, HighlightedText } from 'components/shared/Typography'
import { Col, Row } from 'components/shared/Grid'
import SingleInvestmentCard from 'components/layout/Investment/HorizontalCard'

import type { InvestmentCardSimple } from 'types/investments'

type Props = {
  title?: string
  investments: InvestmentCardSimple[]
  offerPage?: boolean
}

type SectionProps = {
  full: boolean
  offerPage?: boolean
}

const Section = styled.section<SectionProps>`
  padding: 3rem 0 4rem;

  ${({ offerPage }) =>
    offerPage &&
    css`
      padding: 1rem 0 4rem;
    `}

  ${({ theme }) => theme.media.sm.max} {
    padding: 3rem 0;
  }
  ${({ theme }) => theme.media.sm.min} {
    ${({ theme }) => theme.media.md.max} {
      & > ${Container} > ${Row} > ${Col} {
        padding: 0.5rem;
      }
    }
  }
  ${({ full }) =>
    full &&
    css`
      min-height: 70vh;
    `}
`

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 300px;
`

const Investments: React.FC<Props> = ({ title, investments, offerPage }) => {
  return (
    <Section full={!!title} id="filter-items" offerPage={offerPage}>
      <Container slim>
        {title && (
          <Heading
            as="h2"
            size={50}
            sizeDiff={0.7}
            weight={500}
            align="center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {investments.length > 0 ? (
          <Row gap="2rem">
            {investments.map(
              ({
                image,
                name,
                description,
                link,
                location,
                avaliableFlats,
                tag,
              }) => (
                <Col key={link} xs={24} sm={12}>
                  <SingleInvestmentCard
                    image={image}
                    name={name}
                    description={description}
                    link={link}
                    location={location}
                    avaliableFlats={avaliableFlats}
                    tag={tag}
                  />
                </Col>
              )
            )}
          </Row>
        ) : (
          <EmptyBox>
            <HighlightedText>Brak wyników</HighlightedText>
          </EmptyBox>
        )}
      </Container>
    </Section>
  )
}

export default Investments
