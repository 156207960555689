import styled, { css, CSSProperties } from 'styled-components'

import { Breakpoints } from 'types/theme'

export type ColSize =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24

type ColProps = {
  [breakpoint in keyof Breakpoints]?: ColSize
} & {
  [breakpoint in keyof Breakpoints as `offset-${breakpoint}`]?: ColSize
} & { padding?: CSSProperties['padding'] }

export const Col = styled.div<ColProps>`
  flex: 0 0 auto;
  padding: ${({ padding, theme }) =>
    padding ||
    `calc(${theme.grid.gap} / 2.5)
    calc(${theme.grid.gap} / 2)`};

  ${(props) =>
    (Object.keys(props.theme.breakpoints) as Array<keyof Breakpoints>)
      .filter((breakpoint) => breakpoint in props)
      .map((breakpoint) => {
        if (breakpoint === 'xs') {
          return css`
            flex-basis: ${(100 / 24) * props[breakpoint]!}%;
            max-width: ${(100 / 24) * props[breakpoint]!}%;
            display: block;
          `
        }
        return css`
          ${({ theme }) => theme.media[breakpoint].min} {
            flex-basis: ${(100 / 24) * props[breakpoint]!}%;
            max-width: ${(100 / 24) * props[breakpoint]!}%;
            display: block;
          }
        `
      })}

  ${(props) =>
    (Object.keys(props.theme.breakpoints) as Array<keyof Breakpoints>)
      .filter((breakpoint) => `offset-${breakpoint}` in props)
      .map(
        (breakpoint) =>
          css`
            ${({ theme }) => theme.media[breakpoint].min} {
              margin-left: ${(100 / 24) *
              props[`offset-${breakpoint}` as keyof typeof props]}%;
            }
          `
      )}
`
