import styled, { CSSProperties } from 'styled-components'

type FlexProps = {
  direction?: CSSProperties['flexDirection']
  wrap?: CSSProperties['flexWrap']
  justifyContent?: CSSProperties['justifyContent']
  justifyItems?: CSSProperties['justifyItems']
  alignItems?: CSSProperties['alignItems']
  alignContent?: CSSProperties['alignContent']
  margin?: CSSProperties['margin']
  padding?: CSSProperties['padding']
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'initial'};
  flex-wrap: ${({ wrap }) => wrap ?? 'initial'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'initial'};
  justify-items: ${({ justifyItems }) => justifyItems ?? 'initial'};
  align-items: ${({ alignItems }) => alignItems ?? 'initial'};
  align-content: ${({ alignContent }) => alignContent ?? 'initial'};
  margin: ${({ margin }) => margin ?? '0'};
  padding: ${({ padding }) => padding ?? '0'};
`
