import styled, { css, CSSProperties } from 'styled-components'
import { Col } from 'components/shared/Grid'

type RowProps = {
  fullHeight?: boolean
  reverse?: boolean

  justifyContent?: CSSProperties['justifyContent']
  alignItems?: CSSProperties['alignItems']

  gap?: CSSProperties['padding']
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
  overflow: visible;

  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};

  margin-left: calc(${({ theme }) => theme.grid.gap} / -2);
  margin-right: calc(${({ theme }) => theme.grid.gap} / -2);

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      min-height: inherit;
      height: 100%;
    `};

  ${({ gap }) =>
    gap &&
    css`
      margin-left: calc(${gap} / -2);
      margin-right: calc(${gap}} / -2);

      ${Col} {
        padding: calc(${gap} / 2.5) calc(${gap} / 2);
      }
    `}
`
