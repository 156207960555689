import styled, { css } from 'styled-components'

type GridProps = {
  fullHeight?: boolean
}

export const Grid = styled.div<GridProps>`
  width: 100%;
  height: auto;

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
`
