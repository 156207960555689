import React from 'react'
import { LocalizedLink } from 'gatsby-theme-i18n'
import styled, { css } from 'styled-components'

import { useTranslation } from 'react-i18next'
import useBreakpoint from 'hooks/useBreakpoint'

import Container from 'components/shared/Container'
import { Text } from 'components/shared/Typography'

import arrowRightIcon from 'assets/icons/arrow-right-crumb.svg'

export type Crumb = {
  name: string
  to?: string
}

type Props = {
  crumbs: Crumb[]
}

const Wrapper = styled.div`
  position: relative;
  z-index: 5;
  background: ${({ theme }) => theme.colors.white100};
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  max-width: 100%;
  overflow-x: auto;
`

const StyledLink = styled(LocalizedLink)<{ hasLink?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  max-width: 100%;
  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      margin: 0 7px 0;
      width: 7px;
      height: 11px;
      background: url(${arrowRightIcon}) no-repeat center;
      background-size: 100%;
    }
  }
  ${({ hasLink }) =>
    hasLink &&
    css`
      &:hover > ${Text} {
        color: ${({ theme }) => theme.colors.black};
      }
    `}
  ${({ theme }) => theme.media.sm.max} {
    &:before {
      content: '';
      display: block;
      margin: 0 7px 0 0;
      width: 7px;
      height: 11px;
      background: url(${arrowRightIcon}) no-repeat center;
      background-size: 100%;
      transform: rotate(180deg);
    }
  }
`

const StyledText = styled(Text).attrs({
  margin: '0',
  size: 13,
  themecolor: 'darkgray',
  transform: 'uppercase',
})`
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const Breadcrumbs: React.FC<Props> = ({ crumbs }) => {
  const { sm } = useBreakpoint()
  const { t } = useTranslation('links')

  const allCrumbs = [{ name: t('homepage'), to: '/' }, ...crumbs]
  const lastClickableCrumb = allCrumbs
    .slice()
    .reverse()
    .find(({ to }) => to)!

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          {sm &&
            allCrumbs.map(({ name, to }) => (
              <StyledLink key={name} hasLink={!!to} as={LocalizedLink} to={to}>
                <StyledText>{name}</StyledText>
              </StyledLink>
            ))}
          {!sm && (
            <StyledLink as={LocalizedLink} to={lastClickableCrumb.to}>
              <StyledText>{lastClickableCrumb.name}</StyledText>
            </StyledLink>
          )}
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Breadcrumbs
