import React from 'react'
import styled, { css } from 'styled-components'

import { LocalizedLink } from 'gatsby-theme-i18n'

import { Heading, Text, WithLine } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'
import Button from 'components/shared/Button'

import type { InvestmentCardSimple } from 'types/investments'

const Wrapper = styled.article`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 1.5rem 2rem;
  ${({ theme }) => theme.media.xs.min} {
    padding: 2rem;
  }
  ${({ theme }) => theme.media.lg.min} {
    min-height: 340px;
  }
`

const InnerContent = styled.div`
  max-width: 300px;
  ${({ theme }) => theme.media.xs.min} {
    & > ${Button} {
      max-width: 180px;
    }
  }
  ${({ theme }) => theme.media.lg.min} {
    margin: 0 auto;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  & > div {
    transition: 0.6s;
  }
  ${({ theme }) => theme.media.lg.max} {
    height: 280px;
  }
`

const InnerWrapper = styled(LocalizedLink)`
  display: flex;
  text-decoration: none;
  &:hover {
    ${Button} {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }
    & > ${Content} {
      border-color: ${({ theme }) => theme.colors.primary};
    }
    & > ${ImageWrapper} > div {
      transform: scale(1.1);
    }
  }
  ${({ theme }) => theme.media.lg.max} {
    flex-direction: column;
  }
`

const AvailableFlats = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding: 0.5rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.white100};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white100};

  ${Text} {
    :first-child {
      margin-right: 12px;
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: 300px;
    width: 100%;
    margin-inline: auto;
  }
`

const Tag = styled.div<{ bgcolor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.35rem 0.5rem;

  ${({ bgcolor }) =>
    bgcolor &&
    css`
      background-color: ${bgcolor};
    `}

  ${({ theme }) => theme.media.lg.min} {
    padding: 0.5rem 1rem;
    ${Text} {
      font-size: 17px;
    }
  }
`

const SingleInvestmentCard: React.FC<InvestmentCardSimple> = ({
  image,
  name,
  location,
  description,
  link,
  availableFlats,
  tag,
}) => {
  return (
    <Wrapper>
      <InnerWrapper to={link}>
        <ImageWrapper>
          <LazyImage src={image.src} alt={image.alt ?? name} fill />
        </ImageWrapper>
        <Content>
          <InnerContent>
            {location && (
              <Text
                size={13}
                weight={500}
                themecolor="primary"
                transform="uppercase"
                margin="0.25rem"
              >
                {location}
              </Text>
            )}
            <Heading
              as="h3"
              transform="uppercase"
              line="1"
              margin="0"
              size={30}
              weight={500}
              sizeDiff={0.3}
            >
              {name}
            </Heading>
            <WithLine margin="0.5rem 0 1rem">
              <Text dangerouslySetInnerHTML={{ __html: description }} />
            </WithLine>
            <Button as="div" outline width="100%">
              Sprawdź
            </Button>
          </InnerContent>
          {availableFlats && (
            <AvailableFlats>
              <Text size={14} weight={400} themecolor="gray" margin="0">
                Dostępne mieszkania:
              </Text>
              <Text
                size={14}
                weight={400}
                themecolor="primary"
                margin="0"
                dangerouslySetInnerHTML={{ __html: availableFlats }}
              />
            </AvailableFlats>
          )}
        </Content>
        <Tag bgcolor={tag.color}>
          <Text
            size={14}
            themecolor="white"
            weight={600}
            dangerouslySetInnerHTML={{ __html: tag.text }}
          />
        </Tag>
      </InnerWrapper>
    </Wrapper>
  )
}

export default SingleInvestmentCard
